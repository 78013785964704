@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../static/fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../static/fonts/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../static/fonts/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../static/fonts/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../static/fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("../static/fonts/material-icon-iconfont.woff2") format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
