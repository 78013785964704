@import "./fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    overflow-y: auto;
}

.buy-me-a-coffee-button {
    background-color: #F1337F;
    color: white;
    transition: 300ms;
}

.buy-me-a-coffee-button:hover {
    filter: brightness(125%);
    color: white;
}

.settings-input[type="text"], .settings-input[type="number"] {
    @apply border border-black text-sm;
    padding: 0 0.15rem;
}
select.settings-input {
    @apply border border-black;
}

::backdrop {
    backdrop-filter: blur(15px) brightness(50%);
}

.divider {
    @apply m-1.5;
    @apply uppercase font-semibold tracking-wider text-neutral-400 text-xs;
    @apply relative flex items-center;

    &:before, &:after {
        content: "";
        @apply block flex-1 h-[1px] bg-neutral-400;
    }

    &:before {
        @apply mr-1.5;
    }
    &:after {
        @apply ml-1.5;
    }
}
